import React from "react"
import { graphql } from "gatsby"

import Layout from "./layout"
import SEO from "./seo"

const Conditions = ({ data }) => {
  const condition = data.markdownRemark
  const description = `Memphis podiatrist Dr. Butler offers info on ${condition.frontmatter.title}, including common causes, symptoms, treatment options, and more.`
  return(
    <Layout >
      <SEO title={condition.frontmatter.title} description={description}/>
      <section className="grey">
        <div className="services">
          <h1>{condition.frontmatter.title}</h1>
          <h2>Symptoms, Treatments, and More</h2>
        </div>
      </section>
      <svg xmlns="http://www.w3.org/2000/svg" className="border" viewBox="0 0 1440 320" preserveAspectRatio="none">
        <path className="grey" d="M0,128L120,138.7C240,149,480,171,720,170.7C960,171,1200,149,1320,138.7L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
      </svg>
      <section className="medium">
       <div dangerouslySetInnerHTML={{ __html: condition.html }} />
      </section>
    </Layout>
  )
}

export default Conditions

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`